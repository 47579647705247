import { TranslateService } from '@ngx-translate/core';
import { ApplicationFacade } from '@shared/data';
import { InjectorProvider, NAVIGATOR } from '@shared/providers';
import { LocalStorageService } from '@shared/services';
import { de, enGB, fr, nl } from 'date-fns/locale';
import { NzI18nService, de_DE, en_US, fr_FR, nl_NL } from 'ng-zorro-antd/i18n';

/**
 * Get the user's preferred language
 * @returns String with short country code
 */
export const getPreferredLanguage = (): string => {
  const appFacade = InjectorProvider.injector.get(ApplicationFacade);
  const localStorage = InjectorProvider.injector.get(LocalStorageService);

  return (
    appFacade.language() ||
    localStorage.get<string>('lastLanguage') ||
    getSystemLanguage() ||
    'en'
  );
};

/**
 * Set a selected language to the localStorage
 * @param language String with short country code
 */
export const setLanguageInStorage = (language: string): void => {
  InjectorProvider.injector
    .get(LocalStorageService)
    .set('lastLanguage', language);
};

/**
 * Get the system's language
 * @returns String with short country code or 'en' as the default
 */
export const getSystemLanguage = (): string => {
  const navigator = InjectorProvider.injector.get(NAVIGATOR);
  const result = navigator.language?.split('-')[0];

  if (result && ['fr', 'de', 'en', 'nl'].includes(result)) {
    return result;
  }

  return 'en';
};

/**
 * Initializes the language settings for the application.
 * @param language - The language code to be set.
 */
export const initLanguage = (language: string): void => {
  const appFacade = InjectorProvider.injector.get(ApplicationFacade);
  const translateService = InjectorProvider.injector.get(TranslateService);
  const nzI18n = InjectorProvider.injector.get(NzI18nService);

  const userProfile = appFacade.userProfile();
  if (appFacade.userProfile()) {
    userProfile.info.language = language;
    appFacade.setUserProfile(userProfile);
  }

  setLanguageInStorage(language);
  translateService.use(language);

  // Set preferred language to Ant Design components
  switch (language) {
    case 'de':
      nzI18n.setDateLocale(de);
      nzI18n.setLocale(de_DE);
      break;
    case 'fr':
      nzI18n.setDateLocale(fr);
      nzI18n.setLocale({
        ...fr_FR,
        QRCode: {
          expired: 'Expiré',
          refresh: 'Actualiser',
          scanned: 'Scanné'
        }
      });
      break;
    case 'nl':
      nzI18n.setDateLocale(nl);
      nzI18n.setLocale(nl_NL);
      break;
    default:
      nzI18n.setDateLocale(enGB);
      nzI18n.setLocale(en_US);
      break;
  }
};
