import { DOCUMENT } from '@angular/common';
import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import {
  AuthenticationResponse,
  ConfirmUser,
  ImpersonateUser,
  InviteUser,
  RequestUserReset,
  SendConfirmationEmail,
  SendInvitationEmail,
  SetUserPassword,
  SignInUser,
  SignUpUser,
  UserProfile
} from '@moxi.gmbh/moxi-typescriptmodels';
import { sendCommand, sendQuery } from '@shared/data/flux';
import { LocalStorageService, NotificationService } from '@shared/services';
import { initLanguage, navigateToUrl } from '@shared/utils';
import { Observable, tap } from 'rxjs';
import { ApplicationFacade } from '../facades/application.facade';

@Injectable({
  providedIn: 'root'
})
export class AuthHttpService {
  private readonly document = inject(DOCUMENT);
  private readonly router = inject(Router);
  private readonly appFacade = inject(ApplicationFacade);
  private readonly localStorageService = inject(LocalStorageService);
  private readonly notificationService = inject(NotificationService);

  private redirectUrl: string;

  constructor() {
    this.scheduleSignOutWhenSessionExpires();
  }

  navigateToLogin(): void {
    this.redirectUrl = this.router.url;
    navigateToUrl('auth/login');
  }

  signUp(command: SignUpUser): void {
    sendCommand('com.moxi.api.authentication.SignUpUser', {
      ...command
    }).subscribe(this.responseHandler(command.email));
  }

  signIn(command: SignInUser): void {
    sendCommand('com.moxi.api.authentication.SignInUser', {
      ...command
    }).subscribe(this.responseHandler(command.userId));
  }

  signOut(): void {
    this.appFacade.setUserProfile(undefined);
    this.document.location.reload();
  }

  impersonate(command: ImpersonateUser): void {
    sendCommand('com.moxi.api.authentication.ImpersonateUser', {
      ...command
    }).subscribe(this.responseHandler(command.userId, true));
  }

  stopImpersonating(): void {
    sendCommand(
      'com.moxi.api.authentication.StopImpersonatingUser',
      {}
    ).subscribe(this.responseHandler(null, true));
  }

  sendPasswordRequestLink = (command: RequestUserReset): Observable<void> =>
    sendCommand('com.moxi.api.authentication.RequestUserReset', {
      ...command
    });

  resendConfirmationEmail(command: SendConfirmationEmail): Observable<void> {
    return sendCommand('com.moxi.api.authentication.SendConfirmationEmail', {
      ...command
    });
  }

  confirm(command: ConfirmUser): Observable<void> {
    return sendCommand('com.moxi.api.authentication.ConfirmUser', {
      ...command
    });
  }

  resetPassword(command: SetUserPassword): void {
    sendCommand('com.moxi.api.authentication.SetUserPassword', {
      ...command
    }).subscribe(this.responseHandler(command.userId));
  }

  getCurrentUserProfile(): Observable<UserProfile> {
    const url = 'auth/login';

    return sendQuery<UserProfile>(
      'com.moxi.api.user.GetMyUserProfile',
      {}
    ).pipe(
      tap({
        error: () => {
          this.appFacade.setUserProfile(undefined);
          navigateToUrl(url);
        },
        next: response => {
          this.appFacade.setUserProfile(response);
          if (!response) {
            navigateToUrl(url);
          }
        }
      })
    );
  }

  inviteUser(payload: InviteUser): Observable<void> {
    return sendCommand<void>('com.moxi.api.authentication.InviteUser', {
      ...payload
    });
  }

  sendInvitationEmail(payload: SendInvitationEmail): Observable<void> {
    return sendCommand<void>(
      'com.moxi.api.authentication.SendInvitationEmail',
      {
        ...payload
      }
    );
  }

  /**
   * Handle the authentication subscribe response
   * @param email User email/Id
   * @param reloadPage Boolean to request page reload
   * @returns A function that handles the authentication response
   */
  private responseHandler(
    email?: string,
    reloadPage = false
  ): (response: AuthenticationResponse) => void {
    return (response: AuthenticationResponse): void => {
      if (response.impersonator) {
        this.localStorageService.set('impersonator', response.impersonator);
      } else {
        this.localStorageService.remove('impersonator');
      }

      if (response.authorizationHeader) {
        this.appFacade.setUserProfile(response.userProfile);
        this.localStorageService.set(
          'Authorization',
          response.authorizationHeader
        );

        initLanguage(response.userProfile.info.language);
      }

      if (response.sessionDeadline) {
        const deadline = new Date(response.sessionDeadline).getTime();

        this.localStorageService.set('SessionDeadline', deadline);
        this.scheduleSignOutWhenSessionExpires();
      }

      if (response.result === 'SUCCESS') {
        this.redirectUrl = this.localStorageService.get<string>('previousUrl');

        navigateToUrl(this.redirectUrl || '', reloadPage);
        this.redirectUrl = null;
        this.localStorageService.remove('previousUrl');
        this.notificationService.closeAll();
      }

      if (response.result === 'NOT_CONFIRMED') {
        navigateToUrl(
          (this.redirectUrl || '').startsWith('auth/confirm')
            ? this.redirectUrl
            : 'auth/confirm/' + encodeURIComponent(email),
          reloadPage
        );
      }
    };
  }

  /**
   * Handle the token deadline
   */
  private scheduleSignOutWhenSessionExpires(): void {
    const sessionDeadline =
      this.localStorageService.get<number>('SessionDeadline');

    if (sessionDeadline) {
      const now = new Date();
      const deadline = new Date(sessionDeadline);

      setTimeout(() => {
        this.signOut();
      }, deadline.getTime() - now.getTime());
    }
  }
}
