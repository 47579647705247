import {
  ActivatedRouteSnapshot,
  BaseRouteReuseStrategy
} from '@angular/router';

export class RouteStrategy extends BaseRouteReuseStrategy {
  /**
   * Check whether a route should be re-used or not.
   * This is determined by adding the following data to a route: data: {reUseRoute: false}}
   * https://blog.bitsrc.io/angular-route-reuse-strategy-c7939ebbf797
   * @param future ActivatedRouteSnapshot
   * @param current ActivatedRouteSnapshot
   * @returns True or False
   */
  shouldReuseRoute(
    future: ActivatedRouteSnapshot,
    current: ActivatedRouteSnapshot
  ): boolean {
    if (future.routeConfig?.data?.reUseRoute) {
      return future.routeConfig.data.reUseRoute;
    }

    return super.shouldReuseRoute(future, current);
  }
}
