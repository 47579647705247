import { Component, inject, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { CheckForUpdateService } from '@shared/services';
import { LanguageSelectorComponent } from '@shared/ui';
import { getPreferredLanguage, initLanguage } from '@shared/utils';

@Component({
  selector: 'moxi-root',
  standalone: true,
  imports: [LanguageSelectorComponent, RouterOutlet],
  template: `<router-outlet></router-outlet>`
})
export class AppComponent implements OnInit {
  private readonly checkForUpdateService = inject(CheckForUpdateService);

  ngOnInit(): void {
    initLanguage(getPreferredLanguage());
    this.checkForUpdateService.init();
  }
}
