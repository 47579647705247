import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';
import { AddressInfo } from '@moxi.gmbh/moxi-typescriptmodels';
import { CheckboxGroup } from '@shared/models';
import { InjectorProvider } from '@shared/providers';
import { from, Observable } from 'rxjs';

/**
 * Copy value to clipboard
 * @param text Text to copy
 * @returns Promise
 */
export function copyToClipboard(text: string): Observable<void> {
  return from(navigator.clipboard.writeText(text));
}

/**
 * Check if a given object/value is empty
 * @param value Object to check
 * @returns True if empty and False if not empty
 */
export const isEmpty = (value: unknown): boolean =>
  value == null ||
  (Array.isArray(value) && !value?.length) ||
  (typeof value === 'object' &&
    !(value instanceof Date) &&
    Object.keys(value)?.length === 0) ||
  (typeof value === 'string' && value.trim()?.length === 0);

/**
 * Check if a given object has null or undefined properties
 * @param value Object to check
 * @returns True if empty and False if not empty
 */
export const hasEmptyProperties = (value: unknown): boolean => {
  if (isEmpty(value)) {
    return true;
  }

  if (typeof value === 'object') {
    return Object.values(value).some(hasEmptyProperties);
  }

  return false;
};

/**
 * Check if all properties of a given object are null or undefined
 * @param obj Object to check
 * @returns True if all properties are null, False otherwise
 */
export const areAllPropertiesNull = (obj: unknown): boolean => {
  if (typeof obj !== 'object' || obj == null) {
    return true;
  }

  return !Object.values(obj).some(
    value =>
      !isEmpty(value) &&
      (typeof value !== 'object' || !areAllPropertiesNull(value))
  );
};

/**
 * Generate a random UUID
 * @returns String with UUID value without dashes (-)
 */
export function uuid(): string {
  if (!crypto?.randomUUID()) {
    return;
  }

  return crypto.randomUUID()?.replace(/-/g, '');
}

/**
 * Replace all occurrences of a substring in a string
 * @param str The original string
 * @param find The substring to find
 * @param replace The string to replace with
 * @returns The modified string
 */
export function replaceAll(str: string, find: string, replace: string): string {
  const regex = new RegExp(find, 'g');
  return str.replace(regex, replace);
}

/**
 * Removes null and undefined properties from an object
 * @param data Object to sanitize
 * @returns Object sanitized
 */
export const removeEmpty = (data): unknown => {
  if (data == null) {
    return;
  }

  // transform properties into key-values pairs and filter all the empty-values
  const entries = Object.entries(data).filter(([, value]) => value != null);

  // map through all the remaining properties and check if the value is an object.
  // if value is object, use recursion to remove empty properties
  const clean = entries.map(([key, v]) => {
    const value = typeof v == 'object' ? removeEmpty(v) : v;
    return [key, value];
  });

  // transform the key-value pairs back to an object.
  return Object.fromEntries(clean);
};

/**
 * Converts a delimited string into an array
 * @param value String to convert
 * @param delimiter Delimiter to split the string
 * @returns Array of strings
 */
export const convertStringToArray = (
  value: string,
  delimiter = ','
): string[] =>
  isEmpty(value)
    ? undefined
    : value.split(delimiter).map(_value => _value.trim());

/**
 * Find an object inside a deeply nested array of objects
 * @param obj Any type of object or array to search
 * @param key Property to search
 * @param value Value to search
 * @returns object
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const deepObjectSearch = <T>(obj: any, key: string, value: any): T => {
  if (obj instanceof Array) {
    // eslint-disable-next-line guard-for-in
    for (const i in obj) {
      const result = deepObjectSearch(obj[i], key, value);
      if (result) {
        return result as T;
      }
    }
  } else {
    if (obj[key] === value) {
      return obj;
    }
    for (const prop in obj) {
      if (typeof obj[prop] === 'object') {
        const result = deepObjectSearch(obj[prop], key, value);
        if (result) {
          return result as T;
        }
      }
    }
  }

  return null;
};

/**
 * Filter an array of deeply nested objects for property values containing a search term
 * @param arr Array to search
 * @param term Search term
 * @returns Filtered array
 */
export const filterNestedArray = <T>(arr: T[], term: string): T[] => {
  const termLowerCased = term.toLowerCase();

  const isMatch = (object): boolean => {
    for (const value of Object.values(object)) {
      if (typeof value === 'object' && value !== null) {
        if (isMatch(value)) {
          return true;
        }
      } else if (
        typeof value === 'string' &&
        value.toLowerCase().includes(termLowerCased)
      ) {
        return true;
      }
    }
    return false;
  };

  return arr.filter(isMatch);
};

/**
 * Converts seconds into time
 * @param duration numeric value in seconds
 * @returns string with time in format HH:MM
 */
export const secondsToTime = (duration: number): string => {
  if (!duration) {
    return;
  }

  const hours = Math.floor(duration / 3600); // 1 Hour = 3600 Seconds
  const minutes = Math.floor((duration % 3600) / 60); // 1 Minute = 60 Seconds

  return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
};

/**
 * Get the initials of a first and last name
 * @param firstName First name
 * @param lastName Last name
 * @returns Initials
 */
export const getNameInitials = (
  firstName?: string,
  lastName?: string
): string => {
  if (firstName && lastName) {
    return `${firstName[0].toUpperCase()}${lastName[0].toUpperCase()}`;
  }

  if (firstName) {
    return firstName[0].toUpperCase();
  }
};

/**
 * Converts distance from meters to kilometers
 * @param distance Distance in meters
 * @returns Distance in kilometers
 */
export const convertMetersToKm = (distance: number): number =>
  distance ? parseFloat((distance / 1000).toFixed(2)) : undefined;

/**
 * Formats the display name for the address info with bold street and number
 * @param address AddressInfo object
 * @returns Formatted display name with bold street and number
 */
export const formatAddressDisplay = (address: AddressInfo): string => {
  if (!address?.zipCode) {
    return;
  }

  const addressSummary = `<b>${address.street} ${address.number}</b>, ${address.zipCode} ${address.city}`;
  const placeName =
    address?.placeName !== removeHtmlTags(addressSummary)
      ? address?.placeName
      : null;

  return placeName
    ? `<b>${placeName}</b>, ${removeHtmlTags(addressSummary)}`
    : addressSummary;
};

/**
 * Removes HTML tags from a string
 * @param text String to remove HTML tags from
 * @returns String without HTML tags
 */
export const removeHtmlTags = (text: string): string =>
  text?.replace(/<[^>]*>?/gm, '');

/**
 * Converts a CheckboxGroup array to a specific type array
 * @param checkboxGroup CheckboxGroup array
 * @returns Array of specific type
 */
export const checkboxGroupToType = <T>(checkboxGroup: CheckboxGroup[]): T[] =>
  checkboxGroup.filter(item => item?.checked).map(item => item.value as T);

/**
 * Converts a Date into a ISO format without timezone and time
 * @param date Date or string to convert
 * @returns String with date in ISO format without timezone
 */
export const getIsoDate = (date: Date | string): string => {
  if (!date || isNaN(new Date(date).getTime())) {
    return;
  }

  if (typeof date === 'string') {
    date = new Date(date);
  }

  return new Date(
    Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0)
  ).toISOString();
};

/**
 * Downloads a Blob from an API response
 * @param blob Blob data
 * @param filename Name of the file to be downloaded
 */
export const downloadBlob = (blob: Blob, filename: string): void => {
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');

  link.href = url;
  link.download = filename;
  link.click();

  URL.revokeObjectURL(url);
};

/**
 * Navigates to a view using a route path
 * @param url Route to navigate to: string | UrlTree
 * @param reload Reload page if True
 */
export const navigateToUrl = (url: string, reload = false): void => {
  if (reload) {
    InjectorProvider.injector.get(DOCUMENT).location.href = url;
    return;
  }

  InjectorProvider.injector.get(Router).navigateByUrl(url);
};

/**
 * Formats a person's name by concatenating the last name and first name.
 *
 * @param firstName - The first name of the patient. Defaults to an empty string if not provided.
 * @param lastName - The last name of the patient. Defaults to an empty string if not provided.
 * @returns The formatted patient name in the format "LastName, FirstName".
 */
export const formatPersonName = (firstName, lastName): string => {
  if (firstName && lastName) {
    return `${lastName}, ${firstName}`;
  }

  if (firstName && !lastName) {
    return `${firstName}`;
  }

  if (!firstName && lastName) {
    return `${lastName}`;
  }

  return '--';
};
