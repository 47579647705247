import { inject, Injectable } from '@angular/core';
import {
  Account,
  AccountPermission,
  AccountRole,
  Team,
  TeamInfo,
  TeamRole,
  UserProfile
} from '@moxi.gmbh/moxi-typescriptmodels';
import { ApplicationStore } from '../store/application.store';
import { IApplicationFacade } from './application-facade.interface';

@Injectable({
  providedIn: 'root'
})
export class ApplicationFacade implements IApplicationFacade {
  private readonly store = inject(ApplicationStore);

  userProfile = this.store.userProfile;
  selectedAccount = this.store.selectedAccount;
  impersonator = this.store.impersonator;
  pageTitle = this.store.pageTitle;
  pageSubTitle = this.store.pageSubTitle;
  showHistoryBackButton = this.store.showHistoryBackButton;
  showMainPageTitle = this.store.showMainPageTitle;

  language = this.store.language;
  userFirstName = this.store.userFirstName;
  userInitials = this.store.userInitials;
  isSelectedAccountUnconfirmed = this.store.isSelectedAccountUnconfirmed;
  isMobile = this.store.isMobile;
  isMobileLandscape = this.store.isMobileLandscape;
  is2xl = this.store.is2xl;
  isLoggedIn = this.store.isLoggedIn;
  isSuperAdmin = this.store.isSuperAdmin;
  isAdmin = this.store.isAdmin;
  isRideBookerWithoutTeam = this.store.isRideBookerWithoutTeam;
  isRideBooker = this.store.isRideBooker;
  isDispatcherWithoutTeam = this.store.isDispatcherWithoutTeam;
  isDispatcher = this.store.isDispatcher;
  hasRideBookingService = this.store.hasRideBookingService;
  hasDispatcherService = this.store.hasDispatcherService;
  hasCeliosIntegrationService = this.store.hasCeliosIntegrationService;
  hasDispoliveIntegrationService = this.store.hasDispoliveIntegrationService;
  hasOnlyOneAccount = this.store.hasOnlyOneAccount;

  setUserProfile(userProfile: UserProfile): void {
    this.store.setUserProfile(userProfile);
  }

  setAccount(account: Account): void {
    this.store.setAccount(account);
  }

  getUniqueAccountId(): string {
    return this.store.getUniqueAccountId();
  }

  getUserAccountPermission(
    user: UserProfile,
    accountId: string
  ): AccountPermission {
    return this.store.getUserAccountPermission(user, accountId);
  }

  getAllAccountTeams(): Team[] {
    return this.store.getAllAccountTeams();
  }

  getTeamInfo(teamId: string): TeamInfo {
    return this.store.getTeamInfo(teamId);
  }

  getTeamsFromRole(role: TeamRole): Team[] {
    return this.store.getTeamsFromRole(role);
  }

  hasAnyAccountRole(accountRoles: AccountRole[]): boolean {
    return this.store.hasAnyAccountRole(accountRoles);
  }

  setHistoryBackButton(showHistoryBackButton: boolean): void {
    this.store.setHistoryBackButton(showHistoryBackButton);
  }

  setPageTitle(title: string, showMainTitle?: boolean): void {
    this.store.setPageTitle(title, showMainTitle);
  }
}
