import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { LocalStorageService } from '@shared/services';

export const authInterceptor: HttpInterceptorFn = (req, next) => {
  // Get the auth token from localStorage
  const authToken = inject(LocalStorageService).get<string>('Authorization');

  // Skip process if no token exists
  if (!authToken) {
    return next(req);
  }

  // Clone the request and replace the original headers
  // and updated with the authorization.
  const authReq = req.clone({
    headers: req.headers.set('Authorization', authToken)
  });

  // send cloned request with header to the next handler.
  return next(authReq);
};
