import { Injectable, Injector, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { InjectorProvider } from '@shared/providers';
import { getPreferredLanguage } from '@shared/utils';

@Injectable({
  providedIn: 'root'
})
export class AppInitializeService {
  private readonly translateService = inject(TranslateService);

  private readonly allowedLanguages = ['en', 'de', 'fr', 'nl'];

  constructor() {
    InjectorProvider.injector = inject(Injector);
  }

  async init(): Promise<boolean> {
    const language = getPreferredLanguage();

    this.translateService.addLangs(this.allowedLanguages);
    this.translateService.setDefaultLang(language);

    return Promise.resolve(true);
  }
}
