import { InjectorProvider } from '@shared/providers';
import { LocalStorageService } from '@shared/services';

export const isSessionExpired = (): boolean => {
  const localStorage = InjectorProvider.injector.get(LocalStorageService);
  const sessionDeadline = localStorage.get<number>('SessionDeadline');

  return !sessionDeadline || new Date(sessionDeadline).getTime() <= Date.now();
};

export const stillHasValidToken = (): boolean => {
  const localStorage = InjectorProvider.injector.get(LocalStorageService);

  return !!(localStorage.get<string>('Authorization') && !isSessionExpired());
};
