import { Injectable, inject, signal } from '@angular/core';
import {
  DistrictInfo,
  GeoLocation,
  UpdateUserInfo
} from '@moxi.gmbh/moxi-typescriptmodels';
import { sendCommand, sendQuery } from '@shared/data/flux';
import { Observable, finalize, tap } from 'rxjs';
import { ApplicationFacade } from '@shared/data';

@Injectable({
  providedIn: 'root'
})
export class CommonHttpService {
  private readonly appFacade = inject(ApplicationFacade);

  private readonly loading = signal(false);

  readonly isLoading = this.loading.asReadonly();

  /**
   * Retrieves district information based on the provided geographical location.
   * Used in the place-autocomplete component to get the district information for the selected address.
   *
   * @param geoLocation - The geographical location for which to get the district information.
   * @returns An observable that emits the district information.
   */
  getDistrict(geoLocation: GeoLocation): Observable<DistrictInfo> {
    this.loading.set(true);

    return sendQuery<DistrictInfo>(`com.moxi.api.geojson.GetDistrict`, {
      geoLocation
    }).pipe(finalize(() => this.loading.set(false)));
  }

  /**
   * Update user profile language
   * Used for in the language change event in the header component
   *
   * @param language The new language to set for the user profile
   * @returns An observable that completes when the profile language is updated
   */
  updateProfileLanguage(language: string): Observable<void> {
    const userProfile = this.appFacade.userProfile();
    const payload: UpdateUserInfo = {
      userId: userProfile.userId,
      info: { ...userProfile.info, language }
    };

    return sendCommand<void>('com.moxi.api.user.UpdateUserInfo', {
      ...payload
    }).pipe(tap(() => this.appFacade.setUserProfile({ info: payload.info })));
  }

  /**
   * Encrypts the provided value and returns an observable of the encrypted string.
   * Used in the encrypt component
   *
   * @param value - The string to be encrypted.
   * @returns An observable that emits the encrypted string.
   */
  encryptValue(value: string): Observable<string> {
    this.loading.set(true);

    return sendCommand<string>('com.moxi.api.admin.EncryptValue', {
      value
    }).pipe(finalize(() => this.loading.set(false)));
  }

  /**
   * Checks if a user has signed up.
   * Used in the profile resolver
   *
   * @param userId - The ID of the user to check.
   * @returns An `Observable` that emits `true` if the user has signed up, otherwise `false`.
   */
  checkIfUserSignedUp(userId: string): Observable<boolean> {
    this.loading.set(true);

    return sendQuery<boolean>('com.moxi.api.user.CheckIfUserSignedUp', {
      userId
    }).pipe(finalize(() => this.loading.set(false)));
  }

  /**
   * Retrieves the Google Maps UI key from the server.
   * Used in the Google Maps resolver
   *
   * @returns {Observable<string>} An observable that emits the Google Maps UI key as a string.
   */
  getGoogleMapsUiKey(): Observable<string> {
    this.loading.set(true);

    return sendQuery<string>(
      'com.moxi.api.refdata.GetGoogleMapsUiKey',
      {},
      {
        responseType: 'text'
      }
    ).pipe(finalize(() => this.loading.set(false)));
  }
}
