import { registerLocaleData } from '@angular/common';
import {
  HttpClient,
  provideHttpClient,
  withInterceptors
} from '@angular/common/http';
import en from '@angular/common/locales/en';
import {
  APP_INITIALIZER,
  ErrorHandler,
  importProvidersFrom,
  isDevMode,
  provideZoneChangeDetection
} from '@angular/core';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import {
  InMemoryScrollingFeature,
  RouteReuseStrategy,
  provideRouter,
  withComponentInputBinding,
  withInMemoryScrolling,
  withViewTransitions
} from '@angular/router';
import { provideServiceWorker } from '@angular/service-worker';
import {
  MissingTranslationHandler,
  TranslateLoader,
  TranslateModule
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AuthService } from '@shared/data';
import { MissingTranslationHelper, SentryErrorHandler } from '@shared/handlers';
import { authInterceptor } from '@shared/interceptors';
import { AppInitializeService } from '@shared/services';
import { RouteStrategy } from '@shared/utils';
import { en_GB, provideNzI18n } from 'ng-zorro-antd/i18n';
import { AppComponent } from './app/app.component';
import { routes } from './app/app.routes';

const inMemoryScrollingFeature: InMemoryScrollingFeature =
  withInMemoryScrolling({
    scrollPositionRestoration: 'top',
    anchorScrolling: 'enabled'
  });

const httpLoaderFactory = (httpClient: HttpClient): TranslateHttpLoader =>
  new TranslateHttpLoader(httpClient);

const initializeApp =
  (appInitService: AppInitializeService) => (): Promise<boolean> =>
    appInitService.init();

registerLocaleData(en);

bootstrapApplication(AppComponent, {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideNzI18n(en_GB),
    importProvidersFrom(
      TranslateModule,
      BrowserModule,
      TranslateModule.forRoot({
        missingTranslationHandler: {
          provide: MissingTranslationHandler,
          useClass: MissingTranslationHelper
        },
        loader: {
          provide: TranslateLoader,
          useFactory: httpLoaderFactory,
          deps: [HttpClient]
        }
      })
    ),
    provideRouter(
      routes,
      withComponentInputBinding(),
      inMemoryScrollingFeature,
      withViewTransitions()
    ),
    AuthService,
    { provide: RouteReuseStrategy, useClass: RouteStrategy },
    provideHttpClient(withInterceptors([authInterceptor])),
    {
      provide: ErrorHandler,
      useClass: SentryErrorHandler
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [AppInitializeService],
      multi: true
    },
    provideAnimationsAsync(),
    provideServiceWorker('ngsw-worker.js', {
      enabled: !isDevMode(),
      registrationStrategy: 'registerWhenStable:30000'
    })
  ]
}).catch(err => console.error(err));
