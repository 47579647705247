import { nutsCodes } from '@shared/utils';

export interface OptionGroup {
  label: string;
  options: Option[];
}

export interface Option {
  label: string;
  value: string;
  hide?: boolean;
}

/**
 * Retrieves the nutsCodes as formatted regions.
 * @returns A promise that resolves to an array of OptionGroup objects.
 */
export const getFormattedRegions = async (): Promise<OptionGroup[]> => {
  const nutsCodeList = await nutsCodes();
  const data: OptionGroup[] = [];

  data.push({
    label: 'global.country', // i18n key
    options: [{ label: 'DE - Germany', value: 'DE' }]
  });

  data.push({
    label: 'global.state', // i18n key
    options: nutsCodeList?.map(state => ({
      label: `${state?.code} - ${state?.name?.trim()}`,
      value: state?.code
    }))
  });

  data.push({
    label: 'global.governmentRegion', // i18n key
    options: nutsCodeList?.flatMap(state =>
      state?.substates.map(subState => ({
        label: `${subState?.code} - (${state?.name?.trim()}) - ${subState?.name?.trim()}`,
        value: subState?.code,
        hide: state?.substates?.length === 1 // Hides Berlin and Bremen state
      }))
    )
  });

  data.push({
    label: 'global.district', // i18n key
    options: nutsCodeList?.flatMap(state =>
      state?.substates?.flatMap(substate =>
        substate.districts?.map(district => ({
          // Makes Bremen district look better
          label: `${district?.code} - (${state?.name?.trim()}${state?.substates?.length === 1 ? '' : ' - ' + substate?.name?.trim()}) - ${district?.name?.trim()}`,
          value: district?.code,
          hide: substate?.districts?.length === 1 // Hides Berlin district
        }))
      )
    )
  });

  return data;
};
