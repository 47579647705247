import { Team } from '@moxi.gmbh/moxi-typescriptmodels';

/**
 * Sorts teams alphabetically
 * @param a - Team A
 * @param b - Team B
 * @returns The sorted teams
 */
export const sortTeamsAlphabetically = (a: Team, b: Team): number =>
  a?.info?.teamName?.localeCompare(b?.info?.teamName);
